<template>
  <v-card>
    <div v-if="currentMappingToken">
      <v-card-title>Edit Mapping Token</v-card-title>
      <v-form class="multi-col-validation">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="currentMappingToken.clientID"
                outlined
                label="Client ID"
                required
                :rules="[v => !!v || 'Client ID is required']"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="currentMappingToken.clientContact"
                outlined
                label="Client Contact"
                required
                :rules="[v => !!v || 'Client Contact is required']"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="currentMappingToken.username"
                outlined
                label="Username"
                required
                :rules="[v => !!v || 'Username is required']"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="currentMappingToken.password"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                outlined
                label="Password"
                required
                :rules="[v => !!v || 'Password is required']"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="currentMappingToken.elexysToken"
                outlined
                label="Token"
                required
                :rules="[v => !!v || 'Token is required']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            @click.prevent="updateMappingToken"
          >
            Save
          </v-btn>
          <v-btn
            outlined
            class="mt-3"
            color="secondary"
            type="reset"
            @click.prevent="resetForm"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import MappingTokenService from '../../services/MappingTokenService'

export default {
  data() {
    return {
      isPasswordVisible: false,
      currentMappingToken: null,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  mounted() {
    this.getMappingToken(this.$route.params.id)
  },
  methods: {
    getMappingToken(id) {
      MappingTokenService.get(id)
        .then(response => {
          this.currentMappingToken = response.data
        })
        .catch(e => {
          if (e.message.indexOf('404') !== -1) {
            this.$router.push({ name: 'not-found' })
          }
        })
    },
    updateMappingToken() {
      MappingTokenService.update(this.currentMappingToken.id, this.currentMappingToken)
        .then(() => {
          this.$router.push({ name: 'mapping-token' })
        })
        .catch(e => {
          console.log(e)
        })
    },
    resetForm() {
      this.$router.push({ name: 'mapping-token' })
    },
  },
}
</script>
